import React, { useEffect, useState, useRef } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import PageNav from '../../components/header/page-navbar.jsx';
import { Input, Jumbotron, Label, Form, Row, Modal, ModalBody, CustomInput } from 'reactstrap';
import BettingStateModal from './BettingStateModal';
import { ApiUrl, ApiKey, Images, WebUrl, NumberMaxField, BET_TYPES } from '../../util/Constant.js';
import PoolPickSwiper from '../../components/content/PoolPickSwiper.js';
import { formatNumber } from '../../util/Utility.js';
import { useSelector, useDispatch } from "react-redux";
import { showJsonResponseAlert, setBusy, setIdle, showConfirm } from "../../redux/AppAction";
import { useTranslation } from 'react-i18next';
import { AppConfiguration } from "read-appsettings-json";
import moment from 'moment';
import { NUMBER_TYPE } from '../../util/Constant';
import classnames from 'classnames';

import MultiSelect from "react-multi-select-component";
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import ApiEngine from '../../util/ApiEngine.js';

/// <summary>
/// Author: -
/// </summary>
const BettingPage = props => {
    const _location = useLocation();
    const _history = useHistory();
    const _dispatch = useDispatch();
    var { t } = useTranslation();
    const _userData = useSelector(state => state.authState.userData);

    const [numberFieldLength, setNumberFieldLength] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);

    const [dateOptions, setDateOptions] = useState([]);
    const [selectedDate, setSelectedDate] = useState([]);
    const [orderCart, setOrderCart] = useState();
    const [keyboardShown, setKeyboardShown] = useState(false);
    const [currentInput, setCurrentInput] = useState();

    const [selectedPoolId, setSelectedPoolId] = useState([0, 1, 2, 3]);
    const [bettingType, setBettingType] = useState('3.4D');
    const [pattern, setPattern] = useState('NORMAL');
    const [subtotal, setSubTotal] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [appBetType, setAppBetType] = useState([]);

    const [betNumber, setBetNumber] = useState("");

    const [dateModal, setDateModal] = useState(false);
    const [selectedDrawDate, setSelectedDrawDate] = useState([]);
    const [remarkModalVisible, setRemarkModalVisible] = useState(false);
    const [remarks, setRemarks] = useState();
    const [creditBalance, setCreditBalance] = useState(0);

    const remarksRef = useRef();
    const keyboard = useRef();

    // this is for permutation usage
    var permArr = [], usedChars = [];
    var permutations = require('permutation');

    useEffect(() => {
        loadCredit();

        setAppBetType(BET_TYPES.filter(b => b.appType.includes(AppConfiguration.Setting().ApplicationConfig.appType)));

        if (_location.state?.betId) {
            betStringParser(_location.state.betId);
        } else {
            var cartObject = JSON.parse(sessionStorage.getItem("OrderCart"));
            if (cartObject != null) {
                setRemarks(cartObject.remark);
                let tempCartObject = calculateOrderCart(cartObject);
                setOrderCart(tempCartObject);
                setSelectedDate(tempCartObject.dates);
            }
        }
    }, []);

    ///
    /// Selection of default date
    ///
    useEffect(() => {
        if (dateOptions.length > 0 && selectedDate.length == 0) {
            let defaultDate = dateOptions[0];
            setSelectedDate([{
                date: defaultDate.label.split(" ")[0],
                label: defaultDate.label,
                value: defaultDate.value
            }]);
        }
    }, [dateOptions]);

    useEffect(() => {
        keyboard.current.setInput("");

        if (bettingType == "3.4D" || bettingType == "4D") {
            setNumberFieldLength(NumberMaxField._4D);
        }
        else if (bettingType == "3D") {
            setNumberFieldLength(NumberMaxField._3D);
        }
        else if (bettingType == "5D") {
            setNumberFieldLength(NumberMaxField._5D);
        }
        else if (bettingType == "6D") {
            setNumberFieldLength(NumberMaxField._6D);
        }
        else if (bettingType == "2D") {
            setNumberFieldLength(NumberMaxField._2D);
        }

        getBetDates();
    }, [_location, bettingType]);

    useEffect(() => {
        calculate();
    }, [selectedDate, pattern]);

    useEffect(() => {
        if (currentInput) {
            setKeyboardShown(true);
            if (currentInput.maxLength > 1) {
                keyboard.current.setInput(currentInput.value);
            } else {
                keyboard.current.setInput("");
            }
        }
    }, [currentInput]);

    useEffect(() => {
        var grandTotalCalculate = 0;
        if (orderCart) {
            orderCart.betList.map((bt, idx1) => {
                grandTotalCalculate += bt.totalAmount;
            });
        }

        setGrandTotal(grandTotalCalculate);
    }, [orderCart]);

    /// <summary>
    /// Author : Wong
    /// </summary>
    const loadCredit = () => {
        ApiEngine.get(ApiUrl._API_GET_CREDIT)
            .then((response) => {
                setCreditBalance(response[ApiKey._API_SUCCESS_KEY] ? formatNumber(response[ApiKey._API_DATA_KEY].creditLeft) : "-");
            })
    }

    async function getTicketDetails(betId, date) {
        let url = ApiUrl._API_GET_TICKET_DETAILS;

        url += `?betId=${betId}` + `&dateTime=${date}`;

        return await ApiEngine.get(url);
    }

    const betStringParser = (betId) => {
        getTicketDetails(betId).then((response) => {
            let data = response.data;
            setDateModal(true);
            setOrderCart(data.cartObject);
        })
    }

    const confirmBet = () => {
        if (orderCart) {
            let orderCartDates = orderCart.dates.map(d => d.value);
            let selectedDates = selectedDate.map(d => d.value);

            if (selectedDate.length == 0) {
                _dispatch(showJsonResponseAlert("Error", t("INVALID_BET_DATE")));
                return;
            } else {
                if (!selectedDates.every(d => orderCartDates.includes(d)) || selectedDates.length != orderCartDates.length) {
                    _dispatch(
                        showConfirm(
                            t("BET_DATE_CHANGE_DESC", { date: selectedDate.map(d => d.label).join(", ") }),
                            () => { addBet() }
                        )
                    );
                    return;
                }
            }
        }
        addBet();
    }

    const pageBackHandler = () => {
        if (orderCart) {
            setModalOpen(true);
        } else {
            _history.push('/');
        }
    }

    const getBetAmountArr = () => {
        let elemArr = document.querySelectorAll(`input[name*=money]`);
        let betArr = [];

        elemArr.forEach((elem) => {
            let nameAttr = elem.name;
            let tag = "money";
            let name = elem.name.substring(nameAttr.indexOf(tag) + tag.length);
            betArr.push({
                name,
                amount: parseInt(elem.value)
            })
        })

        return betArr;
    }

    const addBet = () => {
        // get the following information to be passed to summary page       
        var amountArray = [];
        var activeDateArray = [];
        var activatePoolArray = [];
        var poolshortCodes = [];
        var tempBetList = [];
        var indexId = 0;

        var cartObject = JSON.parse(sessionStorage.getItem("OrderCart"));
        if (cartObject != null) {
            tempBetList = cartObject.betList;
            tempBetList.map((bt, idx1) => {
                indexId = bt.id;
            });
        }

        indexId = indexId + 1;

        try {
            // check for selected date
            if (selectedDate.length == 0) {
                throw t("INVALID_BET_DATE");
            }

            // check if the betNumber Valid
            let betNumberLength = betNumber.toString().length;

            if (betNumberLength < numberFieldLength) {
                if (bettingType == '3.4D' && betNumberLength == 3) {
                    let betAmountArr = getBetAmountArr();
                    // If 3 Digit, must have 3[type] betting type only
                    if (betAmountArr.filter(b => !b.name.startsWith('3') && b.amount > 0).length > 0) {
                        throw t("INVALID_BET_AMOUNT_3D");
                    }
                } else {
                    throw t("INVALID_NUMBER");
                }
            }

            // inspect all the value is it correct
            appBetType.map((bt, idx1) => {
                var types = bt.types;
                if (bt.name === bettingType) {
                    types.map((elm, idx2) => {
                        var moneyValue = document.querySelector(`input[name=money${elm}]`).value == "" ? 0 : document.querySelector(`input[name=money${elm}]`).value;
                        if (isNaN(parseFloat(moneyValue))) {
                            throw t("INVALID_BET_AMOUNT") + elm
                        }
                        else {
                            let shortCode = elm;
                            amountArray.push({ name: elm, shortCode, amount: parseFloat(moneyValue) });
                        }
                    })
                }
            });

            if (amountArray.reduce((sum, curElem) => sum + curElem.amount, 0) == 0) {
                throw t("INVALID_BET_AMOUNT");
            }

            // get the active date
            selectedDate.map((d) => {
                activeDateArray.push({
                    date: d.label.split(' ')[0],
                    label: d.label,
                    value: d.value
                })
            });

            // get the active pool
            selectedPoolId.map((item, index) => {
                if (item.active === true) {
                    activatePoolArray.push(item.poolId);
                    poolshortCodes.push(item.shortCode);
                }
            })

            // populate in the bet list
            tempBetList.push({
                id: indexId,
                number: betNumber,
                betType: amountArray,
                pattern: pattern,
                poolList: activatePoolArray,
                poolShortCodes: poolshortCodes,
                totalAmount: subtotal,
                type: bettingType
            });

            var summaryItem =
            {
                name: bettingType,
                dates: activeDateArray,
                betList: tempBetList
            };

            summaryItem = calculateOrderCart(summaryItem);
            sessionStorage.setItem("OrderCart", JSON.stringify(summaryItem));
            setOrderCart(summaryItem);
            resetBetForm();
        } catch (error) {
            _dispatch(showJsonResponseAlert("Error", error));
            return;
        }
    }

    async function submitBet() {
        try {
            _dispatch(setBusy());
            await ApiEngine.get(ApiUrl._API_GET_CREDIT)
                .then((response) => {
                    if (response[ApiKey._API_SUCCESS_KEY]) {
                        if (grandTotal > response[ApiKey._API_DATA_KEY].creditLeft) {
                            throw "Insufficient credit.";
                        }
                    }
                });

            var cartObject = JSON.parse(sessionStorage.getItem("OrderCart"));
            if (cartObject != null) {
                let url = ApiUrl._API_MAKE_ORDER;

                await ApiEngine.post(url, cartObject)
                    .then((responseJson) => {
                        _dispatch(setIdle());
                        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                            sessionStorage.removeItem("OrderCart");
                            _history.push(WebUrl._BET_SUCCESSFUL, { receipt: responseJson[ApiKey._API_DATA_KEY], betUrl: WebUrl._BETTING_PAGE });
                        }
                        else {
                            _dispatch(showJsonResponseAlert(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY] ?? "Failed to place bet"));
                        }
                    });
            } else {
                _dispatch(setIdle());
            }
        } catch (error) {
            _dispatch(setIdle());
            _dispatch(showJsonResponseAlert("Error", error));
        }
    }

    const handleNumberChange = (e, isBackspace = false) => {
        const name = e.name;
        const maxLength = e.maxLength;
        const value = e.value;
        const [fieldName, fieldIndex] = name.split("-");
        var toFocusInput = null;

        // Check if they hit the max character length
        if (value.length >= maxLength && !isBackspace) {
            // Check if it's not the last input field
            if (parseInt(fieldIndex, 10) < numberFieldLength) {
                // Get the next input field
                toFocusInput = document.querySelector(
                    `input[name=numinput-${parseInt(fieldIndex, 10) + 1}]`
                );
            } else {
                setKeyboardShown(false);
                setCurrentInput();
            }
        } else {
            toFocusInput = document.querySelector(
                `input[name=numinput-${parseInt(fieldIndex, 10) - 1}]`
            );
        }

        // If found, focus the next field
        if (toFocusInput !== null) {
            toFocusInput.focus();
            if (toFocusInput.value.length > 0) {
                toFocusInput.select();
            }
        }
        calculate();
    }

    const handleAmountChange = ((e) => {
        calculate();
    })

    function onKeypadChange(keypadValue) {
        if (currentInput) {
            var keyboardValue = keyboard.current.getInput();
            var canProceed = currentInput.maxLength ? keyboardValue.length <= currentInput.maxLength : true;
            if (canProceed) {
                currentInput.value = keyboardValue;
                if (currentInput.id == "betNumber") {
                    setBetNumber(currentInput.value);
                }

                if (currentInput.value.length == currentInput.maxLength) {
                    setKeyboardShown(false);
                }

                calculate();
            }
        }
    }

    function onKeypadKeyPress(key) {
        switch (key) {
            case "{hide}":
                setKeyboardShown(false);
                break;
            case "{add}":
                confirmBet();
                break;
            case "{bet}":
                submitBet();
                break;
            case "{bksp}":
                currentInput.value = '';
                keyboard.current.setInput('');
                handleNumberChange(currentInput, true);
                break;
            default:
                break;
        }
    }

    function deleteBtnHandler(id) {
        var tempOrderCart = orderCart;
        var remainItems = tempOrderCart.betList.filter((item) => item.id !== id);

        tempOrderCart = { ...tempOrderCart, betList: remainItems };

        if (tempOrderCart.betList.length == 0) {
            sessionStorage.setItem("OrderCart", null);
            setOrderCart(null);
        } else {
            setOrderCart(tempOrderCart);
            sessionStorage.setItem("OrderCart", JSON.stringify(tempOrderCart));
        }
    }

    async function getBetDates() {
        let url = ApiUrl._API_GET_BETDATE;
        await ApiEngine.get(url).then((responseJson) => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let data = responseJson[ApiKey._API_DATA_KEY];

                let options = [];
                data.map((item) => {
                    options.push({
                        label: `${item.date} (${item.id})`,
                        value: item.fullDate
                    });
                })

                setDateOptions(options);
            }
            else {
                _dispatch(showJsonResponseAlert(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
            }
        });
    }

    function calculateOrderCart(order) {
        let tempBetList = order.betList.map((b) => {
            let numberBetTotal = calculate(order.dates, b.poolList, b.number, b.betType, b.pattern);
            b.totalAmount = numberBetTotal;
            return b;
        });

        return { ...order, betList: tempBetList };
    }

    function calculate(dates = null, poolList = null, number = null, betTypeArr = null, selectedPattern = null) {
        var activatedDate = dates != null ? dates.length : selectedDate.length;
        var activatedPool = poolList != null ? poolList.length : selectedPoolId.filter(p => p.active).length;
        var money = 0;
        var timesfactors = 1;
        var selectedPattern = selectedPattern ?? pattern;
        var bettingNumber = number ?? betNumber;

        if (betTypeArr != null) {
            money = betTypeArr.reduce((sum, betType) => {
                return sum + betType.amount;
            }, 0);
        } else {
            appBetType.map((bt, idx1) => {
                var types = bt.types;
                if (bt.name === bettingType) {
                    types.map((elm, idx2) => {
                        var moneyValue = document.querySelector(`input[name=money${elm}]`).value;
                        money = money + (isNaN(moneyValue) || moneyValue === "" ? 0 : parseFloat(moneyValue));
                    })
                }
            });
        }

        if (selectedPattern == 'NORMAL' || selectedPattern == 'IBOX') {
            timesfactors = activatedPool * activatedDate;
        }
        else if (selectedPattern == "R") {
            timesfactors = activatedPool * activatedDate * 2;
        }
        else if (selectedPattern == "P-H" || selectedPattern == "P-B") {
            timesfactors = activatedPool * activatedDate * 10;
        }
        else if (selectedPattern == "BOX") {
            let numberInArray = bettingNumber.toString().split('');
            permArr = [];
            usedChars = [];
            timesfactors = activatedPool * activatedDate * permute(numberInArray).length;
        }
        else if (selectedPattern == "L-H") {
            timesfactors = activatedPool * activatedDate * permutations(bettingNumber.substring(1), { unique: true }).length;
        }
        else if (selectedPattern == "L-B") {
            timesfactors = activatedPool * activatedDate * permutations(bettingNumber.substring(0, bettingNumber.length - 1), { unique: true }).length;
        }

        money *= timesfactors;

        setSubTotal(money);

        return money;
    }

    function permute(input) {
        var i, ch;
        for (i = 0; i < input.length; i++) {
            ch = input.splice(i, 1)[0];
            usedChars.push(ch);

            if (input.length == 0 && !permArr.includes(usedChars.join(""))) {
                permArr.push(usedChars.join(""));
            }
            permute(input);
            input.splice(i, 0, ch);
            usedChars.pop();
        }
        return permArr;
    }

    function resetBetForm() {
        document.querySelectorAll('input[name*=money]').forEach(input => input.value = '');
        setSubTotal(0);
        setKeyboardShown(false);
        setCurrentInput();
        setBetNumber('');
    }

    function renderBetPattern(pattern) {
        let text = "";
        switch (pattern) {
            case "BOX":
                text = "(B)"
                break;
            case "IBOX":
                text = "(IB)";
                break;
            case "P-H":
                text = "(PH)"
                break;
            case "P-B":
                text = "(PB)"
                break;
            case "R":
                text = "(R)"
                break;
            case "L-H":
                text = "(LH)"
                break;
            case "L-B":
                text = "(LB)"
                break;
        }
        return text;
    }

    /// <summary>
    /// To render the betting layout based on the betting type
    /// </summary>
    const renderBettingLayout = (targetBettingType) => {
        const rowscolumn = 3;
        var targetTypes;

        appBetType.map((bettypeelement, index) => {
            if (targetBettingType === bettypeelement.name) {
                targetTypes = bettypeelement.types;
            }
        })

        const rows = [...Array(Math.ceil(targetTypes.length / rowscolumn))];
        const typeRows = rows.map((row, idx) => targetTypes.slice(idx * rowscolumn, idx * rowscolumn + rowscolumn));

        const content = typeRows.map((row, idx) => (
            <Row className="w-100 bet-amount-grid m-0">
                {row.map((elm2, idx2) =>
                    <div className="form-group bet-group">
                        <Label className="theme-text-4">{elm2}</Label>
                        <Input readOnly className='text-center' name={"money" + elm2} maxLength={4} placeholder="0" onFocus={(e) => { setKeyboardShown(true); setCurrentInput(e.target); }} onChange={handleAmountChange} />
                    </div>)
                }
            </Row>
        ));

        return (<> {content} </>);
    }

    /// <summary>
    /// Wong
    /// </summary>
    const handleDrawDateCheck = (e, date) => {
        let temp = selectedDrawDate;
        if (e.target.checked) {
            temp = [...temp, { value: date.value, label: date.label, date: date.label.split(" ")[0] }];
            temp = temp.sort(function (a, b) {
                return moment(a.value, "YYYYMMDD").toDate() - moment(b.value, "YYYYMMDD").toDate();
            });
        } else {
            if (temp.find(d => d.value == date.value)) {
                temp.splice(temp.find(d => d.value == date.value).index, 1);
            }
        }
        setSelectedDrawDate(temp);
    }

    /// <summary>
    /// Wong
    /// </summary>
    const confirmDrawDate = () => {
        setDateModal(false);
        setSelectedDate(selectedDrawDate);

        orderCart.dates = selectedDrawDate;
        let tempCartObject = calculateOrderCart(orderCart);

        setOrderCart(tempCartObject);
        sessionStorage.setItem("OrderCart", JSON.stringify(tempCartObject));
    }

    /// <summary>
    /// Author : Wong
    /// </summary>
    const handleRemarkDone = () => {
        let remarkVal = remarksRef.current.value;

        setRemarks(remarkVal);
        setRemarkModalVisible(false);
        let tempCartObject = {
            ...orderCart,
            remark: remarkVal,
        };

        setOrderCart(tempCartObject);
        sessionStorage.setItem("OrderCart", JSON.stringify(tempCartObject));
    }

    return (
        <>
            <PageNav
                pageTitle={t('BETTING')}
                logoStyle={{ 'background': "white", "border-radius": "50%" }}
                pageBackHandler={pageBackHandler}
                headerOption={
                    <div className="credit-wrapper">
                        {_userData ? _userData.username : "-"}
                        <span className="theme-btn-1 btn-xs badge-credit">
                            <img src={require("../../assets/img/theme/chip.svg")} />
                            {creditBalance}
                        </span>
                    </div>
                }
            />
            <div className="bg-betting-history">
                <div className="bg-1"></div>
            </div>
            <div id="bettingPage" className="set-page-content">
                <div className="page-container container">
                    <Jumbotron className="betting-jumbo betting-brand">
                        <PoolPickSwiper selectedPoolId={selectedPoolId} setSelectedPoolId={setSelectedPoolId} calculate={calculate} betType={bettingType} />
                    </Jumbotron>
                    <MultiSelect
                        className="multiselect-date"
                        options={dateOptions}
                        hasSelectAll={false}
                        value={selectedDate}
                        onChange={setSelectedDate}
                        labelledBy={t("SELECT_DATE")}
                        disableSearch
                    />
                    <Jumbotron className="betting-jumbo betting-num">
                        <div className="number-type-container">
                            {
                                Object.values(NUMBER_TYPE).map((num) => {
                                    return <div
                                        className={classnames('number-type', { active: pattern === num.value })}
                                        value={num.value}
                                        onClick={() => setPattern(num.value)}
                                    >
                                        {t(num.translationKey)}
                                    </div>
                                })
                            }
                        </div>
                        <div className="betting-num-container d-flex">
                            <div className="number-type">
                                <Input className='p-0' type="select" value={bettingType} onChange={(e) => { setBettingType(e.target.value); setBetNumber(''); }}>
                                    {
                                        appBetType.map((b) => {
                                            return <option value={b.name}>{b.label}</option>
                                        })
                                    }
                                </Input>
                            </div>

                            <div className="number-container">
                                <Input className='text-center' readOnly maxLength={numberFieldLength} value={betNumber} id="betNumber" onFocus={e => { e.stopPropagation(); setCurrentInput(e.target); setKeyboardShown(true); }} />
                            </div>

                            <button className="btn theme-btn-1 btn-add-ticket" onClick={confirmBet}>{t('ADD_TICKET')}</button>
                        </div>
                    </Jumbotron>
                    <Form className="bet-layout-wrapper">
                        {
                            appBetType.length > 0 &&
                            renderBettingLayout(bettingType)
                        }
                    </Form>
                    <BettingStateModal isModalOpen={isModalOpen} setModalOpen={setModalOpen} fromConfirmationFooter />
                    {orderCart && <marquee className="bet-notice">{t('BET_NOTICE')}</marquee>}
                    <div style={{ paddingBottom: '4rem' }}>
                        <div className="betlist-wrapper">
                            {
                                orderCart ?
                                    <>
                                        <p className="betdates">
                                            {
                                                orderCart.dates?.map((d) => d.label).join(", ")
                                            }
                                        </p>
                                        {
                                            <div className="remark-wrapper">
                                                {
                                                    remarks &&
                                                    <div style={{ flex: 1 }} className="remark-field">
                                                        {remarks}
                                                    </div>
                                                }
                                                <p className="btn-remark" style={{ flex: remarks ? 0 : 1 }} onClick={() => setRemarkModalVisible(true)}>
                                                    {remarks ? t("EDIT") : t("ADD_REMARK")}
                                                </p>
                                            </div>
                                        }
                                        <div className="table-responsive">
                                            <table className="table table-betitems">
                                                <tbody>
                                                    {
                                                        orderCart.betList?.map((betItem) => {
                                                            return (
                                                                <tr>
                                                                    <th width="1%">
                                                                        <i className="fas fa-trash" onClick={() => deleteBtnHandler(betItem.id)}></i>
                                                                    </th>
                                                                    <th width="1%">
                                                                        <strong className="bet-number">
                                                                            {betItem.number}
                                                                            {renderBetPattern(betItem.pattern)}
                                                                        </strong>
                                                                    </th>
                                                                    <th width="1%">
                                                                        <strong className="bet-number">{betItem.poolShortCodes.join("")}</strong>
                                                                    </th>
                                                                    <td>
                                                                        {
                                                                            betItem.betType.filter((type) => type.amount > 0).map((type) => {
                                                                                return `${type.shortCode}${type.amount}  `
                                                                            })
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                    : <div className="empty-state-bet">{t("EMPTY_BET_DESC")}</div>
                            }
                        </div>
                    </div>
                </div>
                <Keyboard
                    layout={{
                        'default': [
                            "1 2 3 {add} 4 5 6 7 8 9 . 0 {bksp} {hide}"
                        ]
                    }}
                    display={{
                        '{add}': `${t("ADD_TICKET")} <br>${subtotal > 0 ? formatNumber(subtotal) : ''}`,
                        '{bet}': `${t("BET")} <br>${grandTotal > 0 ? formatNumber(grandTotal) : ''}`,
                        '{bksp}': "<i class='fas fa-backspace'></i>",
                        '{hide}': "<i class='fas fa-keyboard'></i>"
                    }}
                    theme={`keyboard keyboard-bet ${keyboardShown ? "keyboard-shown" : ""}`}
                    keyboardRef={r => (keyboard.current = r)}
                    onChange={onKeypadChange}
                    onKeyPress={onKeypadKeyPress}
                    disableButtonHold
                    disableCaretPositioning
                />
                {
                    grandTotal > 0 &&
                    <button type="button" className="btn theme-btn-1 btn-block btn-confirm-bet" onClick={submitBet}>{t("BET")} {grandTotal > 0 ? formatNumber(grandTotal) : ''}</button>
                }
            </div>
            <Modal className="modal-brand" isOpen={dateModal} toggle={setDateModal} centered>
                <ModalBody>
                    <strong className="d-block text-center mb-3">{t("SELECT_DRAW_DATE")}</strong>
                    <ul className="ul-date-selection">
                        {
                            dateOptions.map((d) => {
                                return <li key={d.value}>
                                    <CustomInput type="checkbox" id={`checkbox-${d.value}`} value={d.value} label={d.label} className="custom-control-brand" onChange={(e) => handleDrawDateCheck(e, d)} />
                                </li>
                            })
                        }
                    </ul>
                    <div className="text-center">
                        <button type="button" className="btn btn-min-width theme-btn-1" disabled={selectedDrawDate.length == 0} onClick={confirmDrawDate}>{t("SUBMIT")}</button>
                        <button type="button" className="btn btn-min-width theme-btn-2" onClick={() => { setSelectedDrawDate([]); setOrderCart(); setDateModal(false); }}>{t("CANCEL")}</button>
                    </div>
                </ModalBody>
            </Modal>
            <Modal className="modal-brand" isOpen={remarkModalVisible} toggle={setRemarkModalVisible} centered onOpened={() => remarksRef.current.focus()}>
                <ModalBody>
                    <strong className="d-block text-center mb-3">{t("ENTER_REMARK")}</strong>
                    <div className="mb-3">
                        <Input innerRef={remarksRef} defaultValue={remarks} />
                    </div>
                    <div className="text-center">
                        <button type="button" className="btn btn-min-width theme-btn-1" onClick={handleRemarkDone}>{t("DONE")}</button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default BettingPage;