import React, { useState, useEffect } from "react";
import {
    Jumbotron, Button, Modal, ModalBody, Input
} from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import PageNav from '../../components/header/page-navbar.jsx';
import { useTranslation } from 'react-i18next';
import { ApiUrl, ApiKey, Images, WebUrl } from "../../util/Constant.js";
import moment from 'moment';
import { stringIsNullOrEmpty, formatNumber, createFormBody, getHeaderIdFromReceiptString } from '../../util/Utility.js';
import { useSelector, useDispatch } from "react-redux";
import { showJsonResponseAlert, setBusy, setIdle } from "../../redux/AppAction";
import DatePicker from "react-datepicker";
import DateInput from '../../components/form/DateInput.js'
import ApiEngine from '../../util/ApiEngine';
import CancelStateModel from "../betting/CancelStateModel";

/// <summary>
/// Author: -
/// </summary>
const CheckTicket = props => {
    var { t } = useTranslation();

    const _history = useHistory();
    const _location = useLocation();
    const _dispatch = useDispatch();

    const [targetDate, setTargetDate] = useState(moment());
    const [ticketList, setTicketList] = useState([]);
    const [ticketCount, setTicketCount] = useState(0);
    const [ticketTotal, setTicketTotal] = useState(0);
    const [selectedTicket, setSelectedTicket] = useState('');
    const [cancelTicket, setCancelTicket] = useState('');
    const [ticketId, setTicketId] = useState('');
    const [betNumber, setBetNumber] = useState('');

    useEffect(() => {
        if (_location.state?.previousData) {
            setTargetDate(_location.state?.previousData.drawDate);
            setTicketId(_location.state?.previousData.ticket);
            setBetNumber(_location.state?.previousData.number);
            getTicketList(_location.state?.previousData.drawDate, _location.state?.previousData.ticket, _location.state?.previousData.number);
        }
    }, [])

    async function getTicketList(target = null, ticket = null, number = null) {
        _dispatch(setBusy());
        let url = ApiUrl._API_CHECK_TICKET;
        let params = {
            date: moment(target ?? targetDate).format('MMDD'),
            ticket_id: ticket ?? ticketId,
            bet_number: number ?? betNumber
        }

        await ApiEngine.post(url, createFormBody(params))
            .then((responseJson) => {
                _dispatch(setIdle());
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setTicketTotal(responseJson[ApiKey._API_DATA_KEY]['total']);
                    setTicketCount(responseJson[ApiKey._API_DATA_KEY]['count']);
                    setTicketList(responseJson[ApiKey._API_DATA_KEY]['receipts']);
                }
                else {
                    _dispatch(showJsonResponseAlert(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
                }
            });
    }

    const getShareContent = (receiptString) => {
        let urlEncodedText = receiptString.replace(/\\n/gi, '%0a').replace(/&/g, '');
        return urlEncodedText;
    }

    const renderTickets = () => {
        return <>
            {
                ticketList.length > 0 ?
                    <>
                        {
                            ticketList.map((item) => (
                                <div className="history-content-item m-b-10" onClick={() => { setSelectedTicket(item) }}>
                                    <div className="p-7 d-flex justify-content-between align-items-center">
                                        <div className="bet-item-content" style={{ flex: 1, borderBottom: 'solid' }}>
                                            <pre style={{ color: item.startsWith("&") ? 'red' : 'white', fontSize: '100%' }}><strong>{item.replace(/&/g, '')}</strong></pre>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </>
                    :
                    <div className="d-flex justify-content-center w-100 m-t-20">
                        <small className="theme-text-3 f-s-15">{t('NO_MORE_RECORDS')}</small>
                    </div>
            }
        </>
    }

    return (
        <>
            <PageNav pageTitle={t('CHECK_TICKET')} pageBackHandler={() => { _history.push(WebUrl._HOME) }} showDate={false} />
            <div className="bg-betting-history">
                <div className="bg-1"></div>
            </div>
            <div id="bettingHistory" className="set-page-content">
                <div className="page-container container">
                    <Jumbotron className="gold-jumbotron d-flex datefilter-wrapper" style={{ marginBottom: ".7rem" }}>
                        <div>
                            <img src={Images._CLOCK_ICON} />
                            <div className="date-wrapper">
                                <span className="m-0">{t("DRAW_DATE")}</span>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={moment(targetDate).toDate()}
                                    onChange={setTargetDate}
                                    placeholderText="dd/mm/yyyy"
                                    popperPlacement="bottom-start"
                                    customInput={<DateInput />}
                                />
                            </div>
                            <div className="date-wrapper">
                                <span className="m-0">{t("TICKET_ID")}</span>
                                <Input className="form-control form-control-transparent" onChange={(e) => { setTicketId(e.target.value) }} id="txtTicketXId" placeholder={t('TICKET_ID')} />
                            </div>
                            <div className="date-wrapper">
                                <span className="m-0">{t("BET_NUMBER")}</span>
                                <Input className="form-control form-control-transparent" onChange={(e) => { setBetNumber(e.target.value) }} id="txtBetNumber" placeholder={t('BET_NUMBER')} />
                            </div>
                        </div>
                        <Button className="theme-btn-2 pull-right" onClick={() => getTicketList()}>{t('APPLY')}</Button>
                    </Jumbotron>
                    <div className="mb-2" style={{ textAlign: 'center' }}>
                        <span className="badge badge-brand badge-total-eat">
                            {t('TOTAL')} : <strong>{formatNumber(ticketTotal, 2)}</strong>
                        </span>
                        <span className="badge badge-brand badge-total-eat">
                            {t('COUNT')} : <strong>{ticketCount}</strong>
                        </span>
                    </div>
                    {renderTickets()}
                    <Modal className="modal-brand bettingModal" isOpen={!stringIsNullOrEmpty(selectedTicket)} toggle={() => setSelectedTicket('')} centered>
                        <ModalBody>
                            <p className="f-s-20 sfp-bold text-center">{t("TICKET_ACTION")}</p>
                            <div className='d-flex justify-content-center' style={{ marginBottom: '1rem' }}>
                                <div className="mode-selection" onClick={() => { 
                                    _history.push(WebUrl._BETTING_ADVANCE, 
                                    { betId: getHeaderIdFromReceiptString(selectedTicket), backToTicketList: { drawDate: moment(targetDate).format('YYYYMMDD'), ticket: ticketId, number: betNumber } }) }}>
                                    <strong>{t("REORDER")}</strong>
                                </div>
                                <div className="mode-selection" onClick={() => {
                                    navigator.share({ text: getShareContent(selectedTicket) });
                                    setSelectedTicket('');
                                }}>
                                    <strong>{t("SHARE")}</strong>
                                </div>
                            </div>
                            {
                                !selectedTicket.startsWith('&') && !selectedTicket.toLowerCase().includes('cancelled') &&
                                <div className='d-flex justify-content-center' style={{ marginBottom: '1rem' }}>
                                    <div className="mode-selection"  
                                    onClick={() => { 
                                        _history.push(
                                            WebUrl._BETTING_ADVANCE, 
                                            { betId: getHeaderIdFromReceiptString(selectedTicket), isEdit: true, backToTicketList: { drawDate: moment(targetDate).format('YYYYMMDD'), ticket: ticketId, number: betNumber } }) }}>
                                        <strong>{t("EDIT")}</strong>
                                    </div>
                                    <div className="mode-selection cancel-btn theme-btn-5" onClick={() => { setCancelTicket(true) }}>
                                        <strong>{t("VOID")}</strong>
                                    </div>
                                </div>
                            }
                            <div className="text-center mt-3">
                                <button className="btn theme-btn-2" onClick={() => setSelectedTicket('')}>{t("CLOSE")}</button>
                            </div>
                        </ModalBody>
                    </Modal>
                    {
                        !stringIsNullOrEmpty(selectedTicket) &&
                        <CancelStateModel
                            isModalOpen={cancelTicket}
                            setModalOpen={() => setCancelTicket(!cancelTicket)}
                            betId={getHeaderIdFromReceiptString(selectedTicket)}
                            onCancelDone={() => {
                                setCancelTicket(!cancelTicket);
                                setSelectedTicket('');
                                getTicketList();
                            }} />
                    }
                </div>
            </div>
        </>
    )
}

export default CheckTicket